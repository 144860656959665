import React from "react";
import "./CTAButton.scss";

const CTAButton = ({ text, link }) => {
  return (
    <a className="CTAButton" href={`${link}`}>
      {text}
    </a>
  );
};

export default CTAButton;
