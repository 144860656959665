import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import React from "react";
import HeroImage from "../HeroImage/HeroImage";
import { graphql, useStaticQuery } from "gatsby";
import "../../scss/titleText.scss";

const HeroHome = () => {
  const images = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativePath: {
            in: [
              "homepage/row-1-s.jpg"
              "homepage/row-1-m.jpg"
              "homepage/row-1-l.jpg"
            ]
          }
        }
        sort: { order: ASC, fields: relativePath }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                formats: WEBP
                webpOptions: { quality: 100 }
                quality: 100
              )
            }
          }
        }
      }
    }
  `);

  const small = getImage(images.allFile.edges[2].node);
  const medium = getImage(images.allFile.edges[1].node);
  const large = getImage(images.allFile.edges[0].node);

  return (
    <HeroImage
      children={
        <>
          <figure>
            <GatsbyImage
              loading="lazy"
              className="container--row--large"
              image={large}
              alt="The Fried Bologna Sandwich"
            />
          </figure>
          <figure>
            <GatsbyImage
              loading="lazy"
              className="container--row--medium"
              image={medium}
              alt="The Fried Bologna Sandwich"
            />
          </figure>
          <figure>
            <GatsbyImage
              loading="lazy"
              className="container--row--small"
              image={small}
              alt="The Fried Bologna Sandwich"
            />
          </figure>
          <div className="container--row--title">
            <div class="textWrapper compressedDisplay">
                <h1>
                  <span class="bolognaWhite rotated">THE</span>
                  <span class="bolognaDefault bigTextbolognaDefault">fried</span>
                  <span class="bolognaWhite mediumTextbolognaWhite">bologna</span>
                  <span class="bolognaWhite smallTextbolognaWhite">SANDWICH</span>
                </h1>
            </div>
          </div>
        </>
      }
    />
  );
};

export default HeroHome;
