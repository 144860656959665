import React from "react";
import "./Wunderfully.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import CTAButton from "../CTAButton/CTAButton";

const WunderFully = () => {
  const images = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativePath: {
            in: [
              "bologna_loaf_hero-1532-2.webp"
              "bologna_loaf_hero-500-2.webp"
              "bologna_loaf_hero-768-2.webp"
              "eat-wonderfully.png"
            ]
          }
        }
        sort: { order: ASC, fields: relativePath }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                formats: WEBP
                webpOptions: { quality: 100 }
                quality: 100
              )
            }
          }
        }
      }
    }
  `);

  const small = getImage(images.allFile.edges[1].node);
  const medium = getImage(images.allFile.edges[2].node);
  const large = getImage(images.allFile.edges[0].node);
  const eatWunderfully = getImage(images.allFile.edges[3].node);

  return (
    <section class="wunder-row">
      <figure>
        <GatsbyImage
          loading="lazy"
          className="wunder-row--background wunder-row--background--small"
          image={small}
          alt="Eat Wunderfully Title"
        />
        <GatsbyImage
          loading="lazy"
          className="wunder-row--background wunder-row--background--medium"
          image={medium}
          alt="Eat Wunderfully Title"
        />
        <GatsbyImage
          loading="lazy"
          className="wunder-row--background wunder-row--background--large"
          image={large}
          alt="Eat Wunderfully Title"
        />
      </figure>
      <div className="wunder-row-inner">
          <div class="diagonalDisplay">
              <h2>
                <span class="bolognaDefault smallTextbolognaDefault">EAT</span>
                <span class="bolognaWhite smallTextbolognaWhite">wunder</span>
                <span class="bolognaWhite smallTextbolognaWhite">fully</span>
              </h2>
          </div>
        <p>
          Everything is better with Wunderbar® Bologna. We craft our
          German-style bologna with fine cuts of beef and our unique blend of
          spices to bring you the slightly sweet taste you love. Our bologna
          varieties have not changed since 1972 and we are proud to be #1* in
          grocer's delis across the country. Enjoy our bologna cold or fry it
          for a smoky, deliciously caramelized taste. Don't stop
          there—everything is better with bologna!
        </p>
        <div className="center">
          <CTAButton text="LEARN MORE" link="/about/" />
        </div>
        <div className="wunder-row-footer">* Nielsen data as of 1/31/19</div>
      </div>
    </section>
  );
};

export default WunderFully;
