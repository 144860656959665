import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./OurProducts.scss";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const OurProducts = () => {
  const images = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativePath: {
            in: [
              "product-1.png"
              "product-2.png"
              "row-4-background.png"
              "row-4-title.png"
            ]
          }
        }
        sort: { order: ASC, fields: relativePath }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                formats: WEBP
                webpOptions: { quality: 100 }
                quality: 100
              )
            }
          }
        }
      }
    }
  `);

  const germanBrand = getImage(images.allFile.edges[0].node);
  const beefBologna = getImage(images.allFile.edges[1].node);
  const background = getImage(images.allFile.edges[2].node);
  const title = getImage(images.allFile.edges[3].node);

  return (
    <section className="ourProducts">
      <Container>
        <Row>
          <figure className="ourProducts__title">
            <div class="linearDisplay">
                <h2>
                  <span class="bolognaWhite smallTextbolognaWhite">our</span>
                  <span class="bolognaDefault  smallTextbolognaDefault">Products</span>
                </h2>
            </div>
          </figure>
        </Row>
        <Row className="ourProducts__container justify-content-md-center">
          <GatsbyImage
            loading="lazy"
            className="ourProducts__container__background"
            image={background}
            alt="Background"
          />
          <Col xs="12" md="6" lg="6" xl="5">
            <div className="ourProducts__product">
              <div className="ourProducts__product__title">
                <h4>
                  German Brand Bologna
                  <small>Made with pork, chiken, beef</small>
                </h4>
              </div>
              <figure className="ourProducts__product__image">
                <GatsbyImage
                  loading="lazy"
                  image={germanBrand}
                  alt="German Brand Bologna"
                />
              </figure>
              <div className="ourProducts__product__specs">
                <div className="ourProducts__product__specs__item">
                  <span>Calories</span>
                  <span className="ourProducts__product__specs__item__value">
                    180
                  </span>
                  <span></span>
                </div>
                <div className="ourProducts__product__specs__item">
                  <span>Cholesterol</span>
                  <span className="ourProducts__product__specs__item__value">
                    40
                  </span>
                  <span>MG</span>
                </div>
                <div className="ourProducts__product__specs__item">
                  <span>Sat Fat</span>
                  <span className="ourProducts__product__specs__item__value">
                    5
                  </span>
                  <span>G</span>
                </div>
                <div className="ourProducts__product__specs__item">
                  <span>Carbs</span>
                  <span className="ourProducts__product__specs__item__value">
                    5
                  </span>
                  <span>G</span>
                </div>
                <div className="ourProducts__product__specs__item">
                  <span>Sodium</span>
                  <span className="ourProducts__product__specs__item__value">
                    610
                  </span>
                  <span>MG</span>
                </div>
                <div className="ourProducts__product__specs__item">
                  <span>Protein</span>
                  <span className="ourProducts__product__specs__item__value">
                    6
                  </span>
                  <span>G</span>
                </div>
              </div>
              <div className="ourProducts__product__nutritional">
                <div className="ourProducts__product__nutritional__header">
                  <div className="ourProducts__product__nutritional__header__item">
                    SERV SIZE: <span>2 oz</span>
                  </div>
                  <div className="ourProducts__product__nutritional__header__item">
                    CALORIES: <span>180</span>
                  </div>
                  <div className="ourProducts__product__nutritional__header__item">
                    FAT CALORIES: <span>130</span>
                  </div>
                </div>
                <div className="ourProducts__product__nutritional__info">
                  <Row className="ml-0 mr-0">
                    <Col xs="12" md="6" lg="6">
                      <ul>
                        <li>
                          <small>AMOUNT/SERVING</small>
                          <span className="ourProducts__product__nutritional__info--right">
                            <small>%DV*</small>
                          </span>
                        </li>
                        <li>
                          <strong>TOTAL FAT</strong> 15G
                          <span className="ourProducts__product__nutritional__info--right">
                            23%
                          </span>
                        </li>
                        <li className="ourProducts__product__nutritional__info--indent">
                          SATURATED FAT 5G
                          <span className="ourProducts__product__nutritional__info--right">
                            25%
                          </span>
                        </li>
                        <li className="ourProducts__product__nutritional__info--indent">
                          TRANS FAT 0G
                        </li>
                        <li>
                          <strong>CHOLESTEROL</strong> 40MG
                          <span className="ourProducts__product__nutritional__info--right">
                            13%
                          </span>
                        </li>
                        <li>
                          <strong>SODIUM</strong> 610MG
                          <span className="ourProducts__product__nutritional__info--right">
                            25%
                          </span>
                        </li>
                      </ul>
                    </Col>
                    <Col xs="12" md="6" lg="6">
                      <ul>
                        <li className="ourProducts__product__nutritional__info--hidden">
                          <small>AMOUNT/SERVING</small>
                          <span className="ourProducts__product__nutritional__info--right">
                            <small>%DV*</small>
                          </span>
                        </li>
                        <li>
                          <strong>TOTAL CARBS</strong> 5G
                          <span className="ourProducts__product__nutritional__info--right">
                            2%
                          </span>
                        </li>
                        <li className="ourProducts__product__nutritional__info--indent">
                          DIETARY FIBER 0G
                          <span className="ourProducts__product__nutritional__info--right">
                            0%
                          </span>
                        </li>
                        <li className="ourProducts__product__nutritional__info--indent">
                          SUGARS 4G
                        </li>
                        <li>
                          <strong>PROTEIN</strong> 6G
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                <div className="ourProducts__product__nutritional__footer">
                  <div className="ourProducts__product__nutritional__footer__item">
                    VITAMIN A: <span>0%</span>
                  </div>
                  <div className="ourProducts__product__nutritional__footer__item">
                    VITAMIN C: <span>0%</span>
                  </div>
                  <div className="ourProducts__product__nutritional__footer__item">
                    CALCIUM: <span>4%</span>
                  </div>
                  <div className="ourProducts__product__nutritional__footer__item">
                    IRON: <span>4%</span>
                  </div>
                </div>
              </div>
              <div className="ourProducts__product__footNote">
                <p>*Percent Daily Values are based on a 2,000 calorie diet.</p>
              </div>
            </div>
          </Col>
          <Col
            xs="12"
            md="6"
            lg="6"
            xl="5"
            className="ourProducts__product--last"
          >
            <div className="ourProducts__product">
              <div className="ourProducts__product__title">
                <h4>Beef Bologna</h4>
              </div>
              <figure className="ourProducts__product__image">
                <GatsbyImage
                  loading="lazy"
                  image={beefBologna}
                  alt="Beef Bologna"
                />
              </figure>
              <div className="ourProducts__product__specs">
                <div className="ourProducts__product__specs__item">
                  <span>Calories</span>
                  <span className="ourProducts__product__specs__item__value">
                    170
                  </span>
                  <span></span>
                </div>
                <div className="ourProducts__product__specs__item">
                  <span>Cholesterol</span>
                  <span className="ourProducts__product__specs__item__value">
                    30
                  </span>
                  <span>MG</span>
                </div>
                <div className="ourProducts__product__specs__item">
                  <span>Sat Fat</span>
                  <span className="ourProducts__product__specs__item__value">
                    6
                  </span>
                  <span>G</span>
                </div>
                <div className="ourProducts__product__specs__item">
                  <span>Carbs</span>
                  <span className="ourProducts__product__specs__item__value">
                    4
                  </span>
                  <span>G</span>
                </div>
                <div className="ourProducts__product__specs__item">
                  <span>Sodium</span>
                  <span className="ourProducts__product__specs__item__value">
                    630
                  </span>
                  <span>MG</span>
                </div>
                <div className="ourProducts__product__specs__item">
                  <span>Protein</span>
                  <span className="ourProducts__product__specs__item__value">
                    6
                  </span>
                  <span>G</span>
                </div>
              </div>
              <div className="ourProducts__product__nutritional">
                <div className="ourProducts__product__nutritional__header">
                  <div className="ourProducts__product__nutritional__header__item">
                    SERV SIZE: <span>2 oz</span>
                  </div>
                  <div className="ourProducts__product__nutritional__header__item">
                    CALORIES: <span>170</span>
                  </div>
                  <div className="ourProducts__product__nutritional__header__item">
                    FAT CALORIES: <span>130</span>
                  </div>
                </div>
                <div className="ourProducts__product__nutritional__info">
                  <Row className="ml-0 mr-0">
                    <Col xs="12" md="6" lg="6">
                      <ul>
                        <li>
                          <small>AMOUNT/SERVING</small>
                          <span className="ourProducts__product__nutritional__info--right">
                            <small>%DV*</small>
                          </span>
                        </li>
                        <li>
                          <strong>TOTAL FAT</strong> 14G
                          <span className="ourProducts__product__nutritional__info--right">
                            22%
                          </span>
                        </li>
                        <li className="ourProducts__product__nutritional__info--indent">
                          SATURATED FAT 6G
                          <span className="ourProducts__product__nutritional__info--right">
                            30%
                          </span>
                        </li>
                        <li className="ourProducts__product__nutritional__info--indent">
                          TRANS FAT 0G
                        </li>
                        <li>
                          <strong>CHOLESTEROL</strong> 30MG
                          <span className="ourProducts__product__nutritional__info--right">
                            10%
                          </span>
                        </li>
                        <li>
                          <strong>SODIUM</strong> 630MG
                          <span className="ourProducts__product__nutritional__info--right">
                            26%
                          </span>
                        </li>
                      </ul>
                    </Col>
                    <Col xs="12" md="6" lg="6">
                      <ul>
                        <li className="ourProducts__product__nutritional__info--hidden">
                          <small>AMOUNT/SERVING</small>
                          <span className="ourProducts__product__nutritional__info--right">
                            <small>%DV*</small>
                          </span>
                        </li>
                        <li>
                          <strong>TOTAL CARBS</strong> 4G
                          <span className="ourProducts__product__nutritional__info--right">
                            1%
                          </span>
                        </li>
                        <li className="ourProducts__product__nutritional__info--indent">
                          DIETARY FIBER 0G
                          <span className="ourProducts__product__nutritional__info--right">
                            0%
                          </span>
                        </li>
                        <li className="ourProducts__product__nutritional__info--indent">
                          SUGARS 2G
                        </li>
                        <li>
                          <strong>PROTEIN</strong> 6G
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                <div className="ourProducts__product__nutritional__footer">
                  <div className="ourProducts__product__nutritional__footer__item">
                    VITAMIN A: <span>0%</span>
                  </div>
                  <div className="ourProducts__product__nutritional__footer__item">
                    VITAMIN C: <span>0%</span>
                  </div>
                  <div className="ourProducts__product__nutritional__footer__item">
                    CALCIUM: <span>2%</span>
                  </div>
                  <div className="ourProducts__product__nutritional__footer__item">
                    IRON: <span>4%</span>
                  </div>
                </div>
              </div>
              <div className="ourProducts__product__footNote">
                <p>*Percent Daily Values are based on a 2,000 calorie diet.</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OurProducts;
