import React from "react";
import "./DeliciousBanner.scss";
import CTAButton from "../CTAButton/CTAButton";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const DeliciousBanner = () => {
  const images = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativePath: {
            in: [
              "row-2-background.jpg"
              "delicious-small.png"
              "delicious-medium.png"
              "delicious-large.png"
            ]
          }
        }
        sort: { order: ASC, fields: relativePath }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                formats: WEBP
                webpOptions: { quality: 100 }
                quality: 100
              )
            }
          }
        }
      }
    }
  `);

  const background = getImage(images.allFile.edges[3].node);
  const small = getImage(images.allFile.edges[2].node);
  const medium = getImage(images.allFile.edges[1].node);
  const large = getImage(images.allFile.edges[0].node);

  return (
    <section className="delicious_container">
      <figure>
        <GatsbyImage
          loading="lazy"
          className="delicious_container__background"
          image={background}
          alt="Delicious Then, Delicious Now-small"
        />
      </figure>
      <div className="delicious_container__delicious_banner">
        <figure>
          <GatsbyImage
            loading="lazy"
            className="delicious_container__delicious_banner__header delicious_container__delicious_banner__header--small"
            image={small}
            alt="Delicious Then, Delicious Now-small"
          />
        </figure>
        <figure>
          <GatsbyImage
            loading="lazy"
            className="delicious_container__delicious_banner__header delicious_container__delicious_banner__header--medium"
            image={medium}
            alt="Delicious Then, Delicious Now-medium"
          />
        </figure>
          <div class="textWrapper linearDisplay">
              <h2>
                <span class="bolognaDefault smallTextbolognaDefault">Delicious</span>
                <span class="bolognaWhite smallTextbolognaWhite">then,</span>
                <span class="bolognaWhite smallTextbolognaWhite">delicious</span>
                <span class="bolognaDefault smallTextbolognaDefault">Now.</span>
              </h2>
          </div>
        <p style={{ zIndex: "20" }} className="text">
          Bologna? Yes, please! Everyone's lunchbox hero has grown up and is
          showing up in surprising ways. We know it was delicious then and it's
          just as delicious now. Serve the sweet taste of the German-style
          bologna that you grew up eating. Wunderbar® Bologna is available
          sliced-to-order at your grocer's deli. It’s just too good NOT to
          share!
        </p>
        <div style={{ zIndex: "20" }} className="center">
          <CTAButton
            text="CHECK OUT OUR FEATURED RECIPE"
            link="/recipes/fried-bologna-sandwich/"
          />
        </div>
      </div>
    </section>
  );
};

export default DeliciousBanner;
